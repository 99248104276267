var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formStudent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Data Pribadi")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"NIM","label-for":"h-nim","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"NIM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nim","placeholder":"Nomor Induk Mahasiswa","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.nim),callback:function ($$v) {_vm.$set(_vm.model, "nim", $$v)},expression:"model.nim"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Mahasiswa","label-for":"h-name","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Nama Mahasiswa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-name","placeholder":"Nama Mahasiswa","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"No. Telepon","label-for":"h-phone-number","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"No. Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-phone-number","placeholder":"No. Telepon","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.phoneNumber),callback:function ($$v) {_vm.$set(_vm.model, "phoneNumber", $$v)},expression:"model.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Alamat","label-for":"h-address","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-address","placeholder":"Alamat","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"h-email","label-cols-md":"2"}},[_c('b-form-input',{attrs:{"id":"h-email","placeholder":"Email","disabled":_vm.config.action === 'show'},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"h-pob","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-pob","placeholder":"Tempat Lahir","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.pob),callback:function ($$v) {_vm.$set(_vm.model, "pob", $$v)},expression:"model.pob"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"h-dob","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-dob","placeholder":"Tanggal Lahir","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.dob),callback:function ($$v) {_vm.$set(_vm.model, "dob", $$v)},expression:"model.dob"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mahasiwa Aktif","label-for":"h-dob","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Mahasiswa Aktif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-primary",attrs:{"checked":"true","state":errors.length > 0 ? false : null,"disabled":_vm.config.action === 'show',"name":"check-button","switch":""},model:{value:(_vm.model.active),callback:function ($$v) {_vm.$set(_vm.model, "active", $$v)},expression:"model.active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-2 mt-2",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Riwayat Perkulihan")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Program Studi","label-for":"h-prodi","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Program Studi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-prodi","placeholder":"Program Studi","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.prodi),callback:function ($$v) {_vm.$set(_vm.model, "prodi", $$v)},expression:"model.prodi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Fakultas","label-for":"h-faculty","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Fakultas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-faculty","placeholder":"Fakultas","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.faculty),callback:function ($$v) {_vm.$set(_vm.model, "faculty", $$v)},expression:"model.faculty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Semester","label-for":"h-semester","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Semester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-semester","placeholder":"Semester","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.semester),callback:function ($$v) {_vm.$set(_vm.model, "semester", $$v)},expression:"model.semester"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Kembali ")]),(_vm.config.action == 'edit')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]):_vm._e()],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }