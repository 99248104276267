<template>
  <b-card>
    <validation-observer ref="formStudent" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <b-row>
          <b-col cols="12" class="mb-2"><h3>Data Pribadi</h3></b-col>

          <b-col cols="12">
            <b-form-group label="NIM" label-for="h-nim" label-cols-md="2">
              <validation-provider
                #default="{ errors }"
                name="NIM"
                rules="required"
              >
                <b-form-input
                  id="h-nim"
                  placeholder="Nomor Induk Mahasiswa"
                  :disabled="config.action === 'show'"
                  v-model="model.nim"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Nama Mahasiswa"
              label-for="h-name"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Mahasiswa"
                rules="required"
              >
                <b-form-input
                  id="h-name"
                  placeholder="Nama Mahasiswa"
                  :disabled="config.action === 'show'"
                  :state="errors.length > 0 ? false : null"
                  v-model="model.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="No. Telepon"
              label-for="h-phone-number"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="No. Telepon"
                rules="required"
              >
                <b-form-input
                  id="h-phone-number"
                  placeholder="No. Telepon"
                  :disabled="config.action === 'show'"
                  v-model="model.phoneNumber"
                  :state="errors.length > 0 ? false : null"
                /><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Alamat"
              label-for="h-address"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Alamat"
                rules="required"
              >
                <b-form-input
                  id="h-address"
                  placeholder="Alamat"
                  :disabled="config.action === 'show'"
                  v-model="model.address"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Email" label-for="h-email" label-cols-md="2">
              <b-form-input
                id="h-email"
                placeholder="Email"
                :disabled="config.action === 'show'"
                v-model="model.email"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Tempat Lahir"
              label-for="h-pob"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  id="h-pob"
                  placeholder="Tempat Lahir"
                  :disabled="config.action === 'show'"
                  v-model="model.pob"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Tanggal Lahir"
              label-for="h-dob"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Tempat Lahir"
                rules="required"
              >
                <b-form-input
                  id="h-dob"
                  placeholder="Tanggal Lahir"
                  :disabled="config.action === 'show'"
                  v-model="model.dob"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Mahasiwa Aktif"
              label-for="h-dob"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Mahasiswa Aktif"
                rules="required"
              >
                <b-form-checkbox
                  checked="true"
                  v-model="model.active"
                  :state="errors.length > 0 ? false : null"
                  :disabled="config.action === 'show'"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2 mt-2"><h3>Riwayat Perkulihan</h3></b-col>

          <b-col cols="12">
            <b-form-group
              label="Program Studi"
              label-for="h-prodi"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Program Studi"
                rules="required"
              >
                <b-form-input
                  id="h-prodi"
                  placeholder="Program Studi"
                  :disabled="config.action === 'show'"
                  v-model="model.prodi"
                  :state="errors.length > 0 ? false : null"
                /><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Fakultas"
              label-for="h-faculty"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Fakultas"
                rules="required"
              >
                <b-form-input
                  id="h-faculty"
                  placeholder="Fakultas"
                  :disabled="config.action === 'show'"
                  v-model="model.faculty"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Semester"
              label-for="h-semester"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Semester"
                rules="required"
              >
                <b-form-input
                  id="h-semester"
                  placeholder="Semester"
                  :disabled="config.action === 'show'"
                  v-model="model.semester"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="$router.go(-1)"
            >
              Kembali
            </b-button>
            <b-button
              v-if="config.action == 'edit'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        action: this.$route.meta.action,
        api: "students",
        redirect: "student",
      },
      model: {},
    };
  },
  created() {
    const _ = this;
    _.loadData();
  },
  methods: {
    loadData() {
      const _ = this;
      _.$axios.get(`${_.config.api}/${_.$route.params.id}`).then((res) => {
        const consume = res.data;
        _.model = consume.data;
      });
    },
    save() {
      const _ = this;
      _.$axios
        .put(`${_.config.api}/${_.$route.params.id}`, _.model)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              text: "Data Berhasil di Ubah",
            },
          });
        });
    },
  },
};
</script>

<style></style>
